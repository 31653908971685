// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-price-table-container {
  border-right: 1px solid #ccc;
  border-radius: 4px;
  /* max-height: 400px; */
  overflow-y: auto;
}

.product-price-table {
  width: 100%;
  border-collapse: collapse;
}

.product-price-table th,
.product-price-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.product-price-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: bold;
}

.product-price-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* .product-price-table tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
} */

.selected-row {
  background-color: #e0e0e0 !important;
}

/* Styles for the search bar */
.search-bar {
  margin-bottom: 15px;
  width: 100%;
}

.search-bar input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .product-price-table th,
  .product-price-table td {
    padding: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductPriceTable.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;;GAGG;;AAEH;EACE,oCAAoC;AACtC;;AAEA,8BAA8B;AAC9B;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE;;IAEE,YAAY;EACd;AACF","sourcesContent":[".product-price-table-container {\n  border-right: 1px solid #ccc;\n  border-radius: 4px;\n  /* max-height: 400px; */\n  overflow-y: auto;\n}\n\n.product-price-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.product-price-table th,\n.product-price-table td {\n  border: 1px solid #ddd;\n  padding: 10px;\n  text-align: left;\n}\n\n.product-price-table th {\n  background-color: #f2f2f2;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  font-weight: bold;\n}\n\n.product-price-table tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n/* .product-price-table tr:hover {\n  background-color: #f5f5f5;\n  cursor: pointer;\n} */\n\n.selected-row {\n  background-color: #e0e0e0 !important;\n}\n\n/* Styles for the search bar */\n.search-bar {\n  margin-bottom: 15px;\n  width: 100%;\n}\n\n.search-bar input {\n  width: 100%;\n  padding: 8px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n@media (max-width: 768px) {\n  .product-price-table th,\n  .product-price-table td {\n    padding: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
