const formatPrice = (price) => {
  if (!price || price === "") return "N/A";
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const UNIT_ARR = ["", "nghìn", "triệu", "tỷ", "nghìn tỷ", "triệu tỷ"];

const convertToVietnameseWords = (val) => {
  try {
    if (val == null) {
      return "";
    }

    let input = -1;
    if (typeof val === "number") {
      input = Math.round(val);
    } else {
      input = Math.round(parseFloat(val.toString()));
    }

    if (input === 0) {
      return "Không đồng chẵn.";
    }

    const cacheInput = input;

    let rsStr = "";
    let negative = false;
    if (input < 0) {
      negative = true;
      input *= -1;
    }

    if (input > 999999999999999) {
      return "Số tiền không hợp lệ.";
    }

    const group3NumberList = [];

    while (input > 0) {
      const group3Number = input % 1000;
      group3NumberList.push(group3Number);

      input = Math.floor(input / 1000);
    }

    let byPassFirstUnit = false;
    for (let i = group3NumberList.length - 1; i >= 0; i--) {
      const str = formatGroup3Number(
        group3NumberList[i],
        i === 0 && rsStr !== ""
      );

      if (str !== "") {
        rsStr += " " + str + " " + UNIT_ARR[i];
      }

      if (byPassFirstUnit === false) {
        switch (group3NumberList.length) {
          case 5:
            if (i === 3) {
              byPassFirstUnit = true;
              if (str === "") {
                rsStr += " " + UNIT_ARR[i];
              }
            }
            break;
          case 6:
            if (i === 4) {
              if (str === "") {
                rsStr += " " + UNIT_ARR[i];
              }
            } else if (i === 3) {
              byPassFirstUnit = true;
              if (str === "") {
                rsStr += " " + UNIT_ARR[i];
              }
            }
            break;
          default:
            byPassFirstUnit = true;
            break;
        }
      }
    }

    rsStr = rsStr.trim();

    rsStr += " đồng";
    if (cacheInput % 2 === 0) {
      rsStr += " chẵn";
    }

    if (negative) {
      rsStr = "Âm " + rsStr;
    } else {
      rsStr = rsStr.charAt(0).toUpperCase() + rsStr.slice(1);
    }

    return rsStr + ".";
  } catch (e) {
    console.error(e);
    return "Số tiền không hợp lệ";
  }
};

const formatGroup3Number = (i, fullFill) => {
  if (i === 0) {
    return "";
  }

  if (i < 10) {
    let rs = number2Vn(i, 0);
    if (fullFill) {
      rs = "không trăm lẻ " + rs;
    }
    return rs;
  }

  if (i < 100) {
    const chuc = Math.floor(i / 10);
    const dv = i % 10;
    let rs = "";

    if (chuc === 1) {
      rs = "mười";
    } else {
      rs = number2Vn(chuc, 0) + " mươi";
    }

    const dvStr = number2Vn(dv, chuc === 1 && dv === 1 ? 0 : 1);

    if (dvStr !== "") {
      rs += " " + dvStr;
    }

    if (fullFill) {
      rs = "không trăm " + rs;
    }

    return rs;
  }

  let rs = number2Vn(Math.floor(i / 100), 0) + " trăm";

  const chucDv = i % 100;
  const chuc = Math.floor(chucDv / 10);
  const dv = chucDv % 10;

  if (chuc !== 0 || dv !== 0) {
    switch (chuc) {
      case 0:
        rs += " lẻ";
        break;
      case 1:
        rs += " mười";
        break;
      default:
        rs += " " + number2Vn(chuc, 0) + " mươi";
        break;
    }

    if (dv > 0) {
      rs += " " + number2Vn(dv, chuc <= 1 ? 0 : 1);
    }
  }

  return rs;
};

const number2Vn = (i, mCase) => {
  switch (i) {
    case 0:
      return "";
    case 1:
      return mCase === 0 ? "một" : "mốt";
    case 2:
      return "hai";
    case 3:
      return "ba";
    case 4:
      return "bốn";
    case 5:
      return mCase === 0 ? "năm" : "lăm";
    case 6:
      return "sáu";
    case 7:
      return "bảy";
    case 8:
      return "tám";
    case 9:
      return "chín";
    default:
      return "ERROR";
  }
};

const priceUtils = { convertToVietnameseWords, formatPrice };
export default priceUtils;
