import React from "react";
import * as DateFns from "date-fns";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import { Loader, Dialog, Header } from "../components";
import { DocInfo } from "./form";
import PrintForm from "../components/PrintForm";

import DocumentServices from "../services/document.services";
import "./ProductPricing.css";

const dateTemplate = (rowData, column) => {
  let str = DateFns.format(rowData[column.field], "HH:mm:ss dd/MM/yyyy");
  return <span>{str}</span>;
};

const numberTemplate = (rowData, column) => {
  let value = rowData[column.field].toLocaleString("en-US");
  return <span>{value}</span>;
};

const rowsPerPageOptions = [10, 20, 50, 100];

const DocList = () => {
  const toast = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [docList, setDocList] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [selectedDoc, setSelectedDoc] = React.useState(null);
  const [showPrintForm, setShowPrintForm] = React.useState(false);
  const [paggingInfo, setPaggingInfo] = React.useState({
    pageIndex: 0,
    rowsPerPage: rowsPerPageOptions[0],
    sortField: "doc_no",
    sortOrder: 1,
  });

  // load danh sách lần đầu
  React.useEffect(() => {
    onRefresh(paggingInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefresh = async (newPaggingInfo) => {
    if (isLoading !== true) {
      setIsLoading(true);
    }

    const response = await DocumentServices.getDocumentList({
      page_index: newPaggingInfo.pageIndex,
      page_size: newPaggingInfo.rowsPerPage,
      sort: [
        {
          column: newPaggingInfo.sortField,
          order: newPaggingInfo.sortOrder === 1 ? "ASC" : "DESC",
        },
      ],
    });

    setPaggingInfo(newPaggingInfo);
    setDocList(response.data);
    setTotalRows(response.total_rows);
    setIsLoading(false);
  };

  const onPage = (event) => {
    console.log(event);
    const newPaggingInfo = {
      ...paggingInfo,
      pageIndex: event.page,
      rowsPerPage: event.rows,
    };

    onRefresh(newPaggingInfo);
  };

  const onSort = (event) => {
    const newPaggingInfo = {
      ...paggingInfo,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    onRefresh(newPaggingInfo);
  };

  const closeDialogDocInfo = () => {
    setSelectedDoc(null);
  };

  return (
    <div className="product-pricing">
      <Header header={"Danh sách phiếu"} />
      {isLoading && <Loader />}
      <Toast ref={toast} position="top-right" />
      <div style={{ padding: "20px" }}>
        <DataTable
          dataKey="doc_no"
          showGridlines
          stripedRows
          size={"small"}
          paginator={totalRows > rowsPerPageOptions[0]}
          lazy={true}
          selection={selectedDoc}
          value={docList}
          totalRecords={totalRows} // Total count for pagination
          first={paggingInfo.pageIndex * paggingInfo.rowsPerPage}
          rows={paggingInfo.rowsPerPage} // Use the state for rows
          sortField={paggingInfo.sortField}
          sortOrder={paggingInfo.sortOrder}
          rowsPerPageOptions={rowsPerPageOptions}
          selectionMode={"single"}
          onSelectionChange={(e) => setSelectedDoc(e.value)}
          onPage={onPage}
          onSort={onSort}
        >
          <Column sortable field="doc_no" header="Mã phiếu" />
          <Column
            sortable
            field="created_at"
            header="Thời gian"
            body={dateTemplate}
          />
          <Column sortable field="created_by" header="Nhân viên" />
          <Column sortable field="product_name" header="Dòng" />
          <Column sortable field="sku" header="SKU" />
          <Column sortable field="seller_name" header="Khách hàng" />
          <Column
            sortable
            field="total_amount"
            header="Tổng (VND)"
            body={numberTemplate}
            style={{ textAlign: "right" }}
          />
        </DataTable>
      </div>
      {selectedDoc && (
        <Dialog
          style={{ width: "55vw", height: "78vh" }}
          header={`Phiếu: ${selectedDoc.doc_no}`}
          onHide={closeDialogDocInfo}
          buttons={[
            {
              label: "Xem bản in",
              severity: "info",
              icon: "pi pi-eye",
              onClick: () => setShowPrintForm(true),
            },
            { label: "Ok", onClick: closeDialogDocInfo },
          ]}
        >
          <DocInfo doc={selectedDoc}></DocInfo>
        </Dialog>
      )}
      {showPrintForm && (
        <PrintForm
          // visible={showPrintForm}
          onHide={() => setShowPrintForm(false)}
          formData={{
            benBan: selectedDoc.seller_name,
            soDienThoai: selectedDoc.seller_phone,
            diaChi: selectedDoc.seller_address,
            soCCCD: selectedDoc.seller_identify_no,
            ngayCap: selectedDoc.seller_identify_no_date,
            noiCap: selectedDoc.seller_identify_no_provider,
            imei: selectedDoc.imei,
            productName: selectedDoc.product_name,
            unit: "Cái",
            quantity: "1",
            unitPrice: selectedDoc.total_amount,
            totalPrice: selectedDoc.total_amount,
            condition: selectedDoc.product_status,
          }}
          productData={{}}
          docNo={selectedDoc.doc_no}
        />
      )}
    </div>
  );
};

export default DocList;
