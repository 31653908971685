import BaseServices from "./base.services";

class DocumentServices extends BaseServices {
  constructor() {
    super(`${process.env.REACT_APP_BACKEND_URL}/api/doc/product-price-document/`);
  }

  async insertDocument(data) {
    return await this.post("insert", "insert", "document", data);
  }

  async getDocumentList({ page_index = 0, page_size = 10, sort = [], condition = null }) {
    return await this.get("", "", "document", {
      page_index,
      page_size,
      sort,
      condition,
    });
  }
}

const MyServices = new DocumentServices();
export default MyServices;
