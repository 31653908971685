import _ from "lodash";
import localStorageService from "./localStorage.services";

const AuthServices = {
  hasPermission: (want) => {
    if (want == null || want === undefined || want === "") return true;

    if (localStorageService.getCurrenUser().isMaster) {
      return true;
    }

    const permission = localStorageService.get("permission");

    if (permission == null) {
      return false;
    }

    const data = _.find(permission, (o) => {
      return new RegExp(want).test(o);
    });

    return !_.isUndefined(data);
  },
};

export default AuthServices;
