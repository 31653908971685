import React from "react";
import { Toast } from "primereact/toast";
import {
  Loader,
  Dialog,
  FieldInfoV2,
  FieldInputTextareaV2,
} from "../components";
import ProxyPimServices from "../services/proxyPim.services";
import localStorageServices from "../services/localStorage.services";
import * as DateFns from "date-fns";

const mapPimProduct = (values) => {
  let description = values.description
    .split("\n")
    .map((str) => `<p>${str}</p>`)
    .join("\n");
  return {
    parent_id: values.parent_id || -1,
    sku: values.product_sku,
    barcode: values.product_sku,
    nhanh_codeNhanh: values.product_sku,
    name: values.product_name,
    display_name: values.product_name,
    alias: values.product_sku,
    price: Number(values.product_price),
    category_id: Number(values.category_id),
    product_brand_id: Number(values.product_brand_id),
    warranty: 0,
    weight: 0,
    full_description: description,
    stop_selling: false,
    sync_channels: ["nhanhvn"],
    published: false,
    deleted: false,
    allow_backorder: false,
    stock_warning_by_depot: false,
    backorder_maximum_quantity_by_depot: false,
    has_vat: false,
    has_tax: false,
    is_from_business_households: false,
    featured: false,
    pre_order: false,
    ref: {
      title: [],
      sku: [],
      mapping: [],
    },
    spec: [],
    filter: [],
    highlight: [],
    id_nhanh: null,
    old_price: null,
    main_picture_id: null,
    stock_quantity: null,
    order_minimum_quantity: null,
    order_maximum_quantity: null,
    length: null,
    width: null,
    height: null,
    quantity_step: null,
    store_id: null,
    allowed_stock: null,
    stock_warning: null,
    backorder_maximum_quantity: null,
    depot: null,
    imei_max_age: null,
    imei_warning_age: null,
  };
};

const ProductPimCreate = ({
  productPriceInfo,
  parentProduct,
  onCancel,
  onConfirm: onParentConfirm,
  brandOptions,
}) => {
  const toast = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    loadCategoryOptions();

    let newValues = {
      parent_id: parentProduct?.product_id,
      product_sku: null,
      product_name: parentProduct
        ? `[QSD] ${parentProduct.product_name}`
        : null,
      category_name: null,
      category_id: null,
      product_brand_name: null,
      product_brand_id: null,
      product_price: productPriceInfo.price,
      description:
        `Sản phẩm qua sử dụng` +
        "\n- Sale tạo tạm, chờ PM cập nhật" +
        `\n- Nhân viên tạo: ${localStorageServices.getCurrenUser().username
        } : ${localStorageServices.getCurrenUser().email}` +
        (!parentProduct
          ? ""
          : `\n- Sản phẩm mới (gốc) : ${parentProduct.product_sku}`),
    };

    setValues(newValues);
    detectBrandAndCategoryDefault(newValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentProduct, productPriceInfo]);

  const detectBrandAndCategoryDefault = (values) => {
    // chưa render
    if (!values) {
      return;
    }

    let haveChange = false;

    if (values.product_brand_id === null) {
      if (brandOptions != null) {
        let brand = brandOptions.find(
          (item) => item.id === productPriceInfo.brand.id
        );

        if (brand) {
          values.product_brand_id = brand.value;
          values.product_brand_name = brand.label;
          values.brand_alias = brand.alias;
          haveChange = true;
        } else {
          showErr(
            `Không tìm thấy thương hiệu "${productPriceInfo.brand}" trên hệ thống`
          );
        }
      }
    }

    let category_name = null
    let category_alias = null

    switch (productPriceInfo.category) {
      case 'VGA':
        category_name = "card màn hình"
        category_alias = 'VGA'
        break;
      case 'Mainboard':
        category_name = "bo mạch chủ"
        category_alias = 'MB'
        break;
      case 'CPU':
        category_name = "bộ vi xử lý"
        category_alias = 'CPU'
        break;
      default:
        break;
    }

    if (values.category_id === null) {
      if (categoryOptions != null) {

        if (category_name) {
          let category = categoryOptions.find(
            (item) => item.label.toLowerCase() === category_name
          );

          if (category) {
            values.category_id = category.value;
            values.category_name = category.label;
            haveChange = true;
          } else {
            showErr(`Không tìm thấy danh mục ${category_name} trên hệ thống`);
          }
        }
        else {
          showErr(`Danh mục ${category_name} không hợp lệ`);
        }
      }
    }

    if (haveChange) {
      values.product_sku =
        category_alias +
        "-" +
        values.brand_alias +
        "-" +
        productPriceInfo.selectedProduct.dong.toUpperCase().replace(
          /\s+/g,
          "-"
        ) +
        "-QSD-" +
        DateFns.format(new Date(), "yyMMddHHmmss");

      if (!parentProduct) {
        values.product_name = `[QSD] [${productPriceInfo.brand.label.toUpperCase()} ${productPriceInfo.selectedProduct.dong
          }] [Sale tạo tạm] chờ PM cập nhật`;
      }

      setValues({ ...values });
    }
  };

  const loadCategoryOptions = () => {
    if (categoryOptions !== null && categoryOptions.length > 0) {
      return;
    }

    ProxyPimServices.getCategoryList()
      .then((response) => {
        if (response.code !== 200) {
          showErr("Lỗi khi lấy thông tin danh mục: " + response.message);
          setCategoryOptions([]);
        } else {
          setCategoryOptions(
            response.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        }
      })
      .catch((error) => {
        console.error(error);
        showErr(error.message);
        setCategoryOptions([]);
      });
  };

  const showErr = (msg) => {
    if (toast.current) {
      toast.current.show({
        severity: "error",
        detail: msg,
      });
    }
  };

  const onConfirm = () => {
    let newErrors = {};

    if (
      values.product_brand_id === null ||
      values.product_brand_id === undefined
    ) {
      newErrors.product_brand_id = `Không tìm thấy thương hiệu "${productPriceInfo.brand}" trên hệ thống`;
      showErr(newErrors.product_brand_id);
    }

    if (values.category_id === null || values.category_id === undefined) {
      let category_name = null

      switch (productPriceInfo.category) {
        case 'VGA':
          category_name = "Card màn hình"
          break;
        case 'Mainboard':
          category_name = "Bo mạch chủ"
          break;
        case 'CPU':
          category_name = "Bộ vi xử lý"
          break;
        default:
          break;
      }
      newErrors.category_id = `Không tìm thấy danh mục ${category_name} trên hệ thống`;
      showErr(newErrors.category_id);
    }

    if (!values.description || values.description.trim().length < 1) {
      newErrors.description = "Xin nhập thông tin";
    }

    if (!values.product_name || values.product_name.trim().length < 1) {
      newErrors.product_name = "Xin nhập thông tin";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const insertProductObj = mapPimProduct(values);
    setIsLoading(true);

    ProxyPimServices.insertProduct(insertProductObj)
      .then((response) => {
        if (response.code === 200) {
          toast.current.show({
            severity: "info",
            summary: "Tạo sản phẩm QSD thành công.",
            detail: "SKU : " + response.data.sku,
          });

          values.product_id = response.data.id;
          onParentConfirm({ ...values });
        } else {
          showErr(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        showErr(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  detectBrandAndCategoryDefault(values);

  const renderQsdProductInfo = () => {
    if (!values) {
      return;
    }

    return (
      <>
        {values.errorMessage && (
          <h4 style={{ color: "green", margin: 0 }}>{values.errorMessage}</h4>
        )}
        <div className="grid">
          <FieldInfoV2 label={"SKU (*)"} value={values.product_sku} />

          <FieldInputTextareaV2
            label={"Tên (*)"}
            value={values.product_name}
            error={errors.product_name}
            row={2}
            onChange={(e) => {
              setValues({ ...values, product_name: e.target.value });
            }}
          />

          <FieldInfoV2
            label={"Giá (*)"}
            value={values.product_price.toLocaleString("en-US") + " VNĐ"}
          />

          <FieldInfoV2
            label={"Thương hiệu (*)"}
            value={values.product_brand_name}
          />

          <FieldInfoV2 label={"Danh mục (*)"} value={values.category_name} />

          <FieldInputTextareaV2
            label={"Mô tả (*)"}
            value={values.description}
            error={errors.description}
            row={4}
            onChange={(e) => {
              setValues({ ...values, description: e.target.value });
            }}
          />
        </div>
      </>
    );
  };

  return (
    <Dialog
      header="Tạo sản phẩm Qua sử dụng trên PIM"
      style={{ width: "55vw", height: "68vh" }}
      onClose={onCancel}
      buttons={[
        {
          label: "Tạo sản phẩm",
          severity: "success",
          icon: "pi-check",
          onClick: onConfirm,
        },
        {
          label: "Hủy",
          severity: "info",
          icon: "pi-times",
          onClick: onCancel,
          outlined: true,
        },
      ]}
    >
      <>
        {(isLoading || brandOptions == null || categoryOptions == null) && (
          <Loader />
        )}
        <Toast ref={toast} position="top-right" />
        <div className="containter">{renderQsdProductInfo()}</div>
      </>
    </Dialog>
  );
};

export default ProductPimCreate;
