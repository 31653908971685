import React from "react";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";

const Dropdown = ({ label, value, onChange, options, numCol = 4, error }) => {
  return (
    <div className={`field col-${numCol}`}>
      <label htmlFor={label}>{label}</label>
      <PrimeDropdown
        value={value}
        options={options}
        onChange={(e) => onChange(e)}
        placeholder="Select an option"
        style={{ width: "100%" }}
      />
      {error && <small className="p-error">{error}</small>}
    </div>
  );
};

export default Dropdown;
