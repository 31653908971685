import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


// PrimeReact imports
import "primereact/resources/primereact.min.css";                  // core css
import "primeicons/primeicons.css";                                // icons
import "primereact/resources/themes/lara-light-indigo/theme.css";  // theme

import Sidebar from './components/Sidebar';
import ProductPricing from './pages/ProductPricing';
import PriceInsert from './pages/PriceInsert';
import DocList from './pages/DocList';

import './App.css';
import './themes/gearvn.css';

import {PUBLIC_URL} from './config';
import {AppProvider} from './AppContext';

function App() {

  return (
    <Router>
      <AppProvider>
        <div className="App">
          <Sidebar />
          <div className="main-content">
            <Routes>
              <Route path={`${PUBLIC_URL}/`} element={<Navigate to={`${PUBLIC_URL}/product-pricing`} replace />} />
              <Route 
                path={`${PUBLIC_URL}/product-pricing`} 
                element={<ProductPricing/>} 
              />
              <Route 
                path={`${PUBLIC_URL}/price-insert`} 
                element={<PriceInsert/>} 
              />
              <Route 
                path={`${PUBLIC_URL}/doc-list`} 
                element={<DocList/>} 
              />
            </Routes>
          </div>
        </div>
      </AppProvider>
    </Router>
  );
}

export default App;
