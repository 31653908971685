import React, { useState, useEffect } from 'react';
import './ProductPriceTable.css';
import SearchBar from './SearchBar';

const ProductPriceTable = ({ productData }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (productData) {
      setFilteredProducts(productData);
    }
  }, [productData]);

  const handleSearch = (term) => {
    const filtered = productData.filter(product =>
      product.dong.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  return (
    <div className="product-price-table-container">

      <SearchBar data={productData} onSearch={handleSearch} />

      <div className="table-container">
        <table className="product-price-table">
          <thead>
            <tr>
              <th>Dòng</th>
              <th>Giá Base</th>
              <th>Giá hàng hư hỏng</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={index}>
                <td>{product.dong}</td>
                <td>{product.gia_base?.toLocaleString("en-US")}</td>
                <td>{product.gia_hang_hu_hong?.toLocaleString("en-US")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductPriceTable;