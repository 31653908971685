import React from "react";
import PropTypes from "prop-types";

const Backdrop = ({ open = true, children, opacity = 0.68 }) => {
  if (!open) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: `rgba(0, 0, 0, ${opacity})`,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
};

Backdrop.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  opacity: PropTypes.number, 
};

export default Backdrop;
