
import React from 'react';
import * as DateFns from "date-fns";

const RowInfo = ({ label, value }) => {
  return <tr>
    <td>{label}</td>
    <td>{value}</td>
  </tr>
}

const DocInfo = ({ doc }) => {

  return (
    <div>
      <div>
        <h4>Dòng : {doc.product_name}</h4>
        <h4>SKU : {doc.sku}</h4>
        <h4>Nhân viên : {doc.created_by}</h4>
        <h4>Thời gian : {DateFns.format(doc.created_at, "HH:mm:ss dd/MM/yyyy")}</h4>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className="bill doc_info">
          <h4>Thông tin giá</h4>
          <table>
            <thead>
              <tr>
                <th>Mục</th>
                <th>Giá (VNĐ)</th>
              </tr>
            </thead>
            <tbody>
              <RowInfo label={`Giá ${doc.product_status}`} value={`${doc.price_status.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={`Thương hiệu ${doc.product_brand_tier || doc.product_brand}`} value={`${doc.price_tier.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={`Bảo hành ${doc.product_status}`} value={`${doc.price_warranty.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={`Ngoại hình ${doc.product_appearance}`} value={`${doc.price_appearance.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={'Tổng'} value={`${doc.total_amount.toLocaleString('en-US')} VNĐ`} valueStyle={{ fontWeight: 'bold' }} />
            </tbody>
          </table>
        </div>
        <div className="bill doc_info seller">
          <h4>Thông tin Bên bán</h4>
          <table>
            <tbody>
              <RowInfo label={'Tên bên bán'} value={doc.seller_name} />
              <RowInfo label={'Số điện thoại'} value={doc.seller_phone} />
              <RowInfo label={'Địa chỉ'} value={doc.seller_address} />
              <RowInfo label={'Số CCCD'} value={doc.seller_identify_no} />
              <RowInfo label={'Ngày cấp'} value={DateFns.format(doc.seller_identify_no_date, "dd/MM/yyyy")} />
              <RowInfo label={'Nơi cấp'} value={doc.seller_identify_no_provider} />
              <RowInfo label={'IMEI'} value={doc.imei} />
            </tbody>
          </table>
        </div>
      </div>
     
    </div>
  );
};

export default DocInfo;
