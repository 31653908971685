const getBrandTierList = async (category) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/brand-tier?category=${category}`);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch product tier');
    }

    const data = await response.json();
    return data.data;

  } catch (error) {
    console.error('Failed to fetch product tier:', error);
    throw error;
  }
};

const getProductPriceList = async (category) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/price-assistant?category=${category}`);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch product price config');
    }

    const data = await response.json();
    return data.data;

  } catch (error) {
    console.error('Error fetching product data:', error);
    throw error;
  }
};

const getProductPriceConfig = async (category) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/price-config?category=${category}`);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch product price config data');
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Error fetching product config data:', error);
    throw error;
  }
};

const insertProductPrice = async (priceData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/price-assistant/insert`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(priceData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to insert product price data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error insert product price data:', error);
    throw error;
  }
};

const updateProductPriceConfig = async (priceData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/price-config/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(priceData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update product price config data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error update product price config data:', error);
    throw error;
  }
};

const ProductPriceService = { getBrandTierList, getProductPriceList, getProductPriceConfig, insertProductPrice, updateProductPriceConfig };
export default ProductPriceService