import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Loader, Dialog, FieldInfo } from "../components";
import ProxyPimServices from "../services/proxyPim.services";
import ProductPimCreate from "./ProductPimCreate";

const productColumns = [
  { field: "product_sku", header: "SKU" },
  { field: "product_name", header: "Tên sản phẩm" },
  { field: "isOldProduct", header: "Sản phẩm cũ" },
  { field: "product_brand_name", header: "Thương hiệu" },
];

const ProductSkuSelect = ({
  productPriceInfo,
  onCancel,
  onConfirm,
  ...other
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchStr, setSearchStr] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(undefined);
  const toast = React.useRef(null);
  const [isToastRendered, setIsToastRendered] = React.useState(false);
  const [showCreateOldProductForm, setShowCreateOldProductForm] =
    React.useState(false);

  React.useEffect(() => {
    setIsToastRendered(true);
  }, []);

  React.useEffect(() => {
    if (!isToastRendered) {
      return;
    }

    let newSearchStr = productPriceInfo.selectedPimProduct
      ? productPriceInfo.selectedPimProduct.product_sku
      : productPriceInfo.selectedProduct.dong;

    setSearchStr(newSearchStr);
    setSelectedProduct(undefined);
    setProducts([]);
    onSearch(undefined, newSearchStr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPriceInfo, isToastRendered]);

  const showErr = (errorMessage) => {
    if (toast.current) {
      toast.current.show({
        severity: "error",
        detail: errorMessage,
      });
    }
  };

  const showInfo = (errorMessage) => {
    if (toast.current) {
      toast.current.show({
        severity: "info",
        detail: errorMessage,
      });
    }
  };

  const mappingProduct = (response) => {
    let datas = response.data.data.map((item) => {
      return {
        ...item,
        isOldProduct:
          item.product_parent_id && item.product_parent_id !== 0 ? "Yes" : "No",
        validate: {
          brandOK: item.product_brand_id === productPriceInfo.brand.id,
        },
      };
    });

    datas.sort((a, b) => {
      if (a.validate.brandOK !== b.validate.brandOK) {
        return a.validate.brandOK ? -1 : 0;
      }

      if (a.isOldProduct !== b.isOldProduct) {
        return a.isOldProduct === "Yes" ? -1 : 0;
      }

      return a.product_sku.localeCompare(b.product_sku);
    });

    return datas.slice(0, 100);
  };

  const callPimSearch = async (paras, info) => {
    const response = await ProxyPimServices.getProductList(paras);

    if (response.code !== 200) {
      showErr(response.message || response.code);
      return -1;
    }

    const data = mappingProduct(response);
    if (data.length === 0) {
      return 0;
    }

    setProducts(data);
    showInfo(
      `Tìm thấy ${data.length.toLocaleString("en-US")} sản phẩm bằng ${info}`
    );
    return 1;
  };

  const onSearch = async (e, newSearchStr) => {
    newSearchStr = newSearchStr || searchStr;

    if (!newSearchStr) {
      showErr("Xin nhập ít nhất 3 kí tự để tìm kiếm");
      return;
    }

    newSearchStr = newSearchStr.replace(/\s+/g, "%");
    if (newSearchStr.length < 3) {
      showErr("Xin nhập ít nhất 3 kí tự để tìm kiếm");
      return;
    }

    setIsLoading(true);

    try {
      // search bằng name và sku
      let rs = await callPimSearch(
        {
          product_name: newSearchStr,
          sku: newSearchStr,
        },
        "[sku, name]"
      );

      if (rs !== 0) {
        return;
      }
      // search bằng sku
      rs = await callPimSearch({ sku: newSearchStr }, "[sku]");
      if (rs !== 0) {
        return;
      }

      //search bằng name
      await callPimSearch({ product_name: newSearchStr }, "[name]");
    } catch (error) {
      console.error(error);
      showErr(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOk = (e, refSelectedProduct) => {
    refSelectedProduct = refSelectedProduct || selectedProduct;

    if (!refSelectedProduct) {
      showErr("Bạn chưa chọn sản phẩm!");
    } else if (refSelectedProduct.isOldProduct !== "Yes") {
      showErr("Sản phẩm đã chọn không phải là sản phẩm cũ!");
    } else {
      onConfirm(refSelectedProduct);
    }
  };

  const handelCreateNewPimProduct = () => {
    if (selectedProduct && selectedProduct.isOldProduct === "Yes") {
      showErr("Sản phẩm đã chọn không phải là sản phẩm mới!");
    } else {
      setShowCreateOldProductForm(true);
    }
  };

  const renderProductPriceCard = () => {
    return (
      <Card title="Sản phẩm định giá">
        <div className="grid">
          <FieldInfo
            label={"Sản phẩm"}
            value={productPriceInfo.selectedProduct.dong}
          />
          <FieldInfo label={"Tình trạng"} value={productPriceInfo.condition} />
          <FieldInfo label={"Bảo hành"} value={productPriceInfo.warranty} />
          <FieldInfo label={"Ngoại hình"} value={productPriceInfo.appearance} />
          <FieldInfo
            label={"Thương hiệu"}
            value={productPriceInfo.brand.label}
          />
          <FieldInfo
            label={"Giá trị sản phẩm"}
            value={productPriceInfo.price.toLocaleString("en-US") + " VNĐ"}
          />
        </div>
      </Card>
    );
  };

  const onKeyDownSearchStr = (event) => {
    if (event.key === "Enter") {
      onSearch(undefined, searchStr);
    }
  };

  const renderFormSelectProduct = () => {
    return (
      <Card title={"Chọn sản phẩm"}>
        <div className="containter-row">
          <InputText
            style={{ width: "100%" }}
            value={searchStr}
            onKeyDown={onKeyDownSearchStr}
            onChange={(e) => setSearchStr(e.target.value)}
          />
          <Button severity="success" icon="pi pi-search" onClick={onSearch} />
        </div>

        <h4 style={{ color: "green" }}>
          {products.length === 0 && "Không tìm thấy sản phẩm thỏa điều kiện."}
        </h4>

        {products.length > 0 && (
          <DataTable
            value={products}
            showGridlines
            stripedRows
            size={"small"}
            // paginator
            // rows={100}
            // rowsPerPageOptions={[10, 20, 50, 100, 1000]}
            selection={selectedProduct}
            selectionMode={"single"}
            onSelectionChange={(e) => setSelectedProduct(e.value)}
            onRowDoubleClick={(e) => handleOk(e, e.data)}
            dataKey="product_id"
          >
            <Column
              selectionMode="single"
              headerStyle={{ width: "3rem" }}
            ></Column>
            {productColumns.map((col, i) => (
              <Column
                sortable
                key={col.field}
                field={col.field}
                header={col.header}
              />
            ))}
          </DataTable>
        )}
      </Card>
    );
  };

  return (
    <>
      <Dialog
        header={"Chọn sản phẩm (PIM)"}
        style={{ width: "75vw", height: "95vh" }}
        onClose={onCancel}
        buttons={[
          {
            label: "Xác nhận chọn sản phẩm",
            severity: "success",
            icon: " pi-check",
            onClick: handleOk,
          },
          {
            label: "Tạo sản phẩm cũ trên PIM",
            severity: "primary",
            icon: "pi-plus",
            onClick: handelCreateNewPimProduct,
          },
          {
            label: "Hủy",
            severity: "info",
            outlined: true,
            icon: "pi-times",
            onClick: onCancel,
          },
        ]}
      >
        {isLoading && <Loader />}

        <Toast ref={toast} position="top-right" />

        <div className="containter">
          {renderProductPriceCard()}
          {renderFormSelectProduct()}
        </div>

        {showCreateOldProductForm && (
          <ProductPimCreate
            productPriceInfo={productPriceInfo}
            parentProduct={selectedProduct}
            onCancel={() => setShowCreateOldProductForm(false)}
            onConfirm={onConfirm}
            brandOptions={other.brandOptions}
          />
        )}
      </Dialog>
    </>
  );
};

export default ProductSkuSelect;
