import React from "react";
import Dialog from "./Dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

const ProductListDialog = ({
  category,
  visible,
  products,
  defaultSelectedProduct,
  showPrice,
  onSelectProduct
}) => {
  const [filterStr, setFilterStr] = React.useState("");
  const [filteredProducts, setfilteredProducts] = React.useState(products);
  const [selectedProduct, setSelectedProduct] = React.useState(
    defaultSelectedProduct || null
  );
  const toast = React.useRef(null);

  const onCancel = () => {
    onSelectProduct(null);
  };

  const onMyConfirm = () => {
    if (!selectedProduct) {
      toast.current.show({
        severity: "error",
        detail: "Bạn chưa chọn sản phẩm!",
      });
      return;
    }

    onSelectProduct(selectedProduct);
  };

  React.useEffect(() => {
    setfilteredProducts(products);
    setFilterStr("");
  }, [products]);

  const onFilter = (e) => {
    let newFilterStr = e.target.value;
    setFilterStr(newFilterStr);

    if (!newFilterStr) {
      setfilteredProducts(products);
    } else {
      newFilterStr = newFilterStr.trim().toLowerCase();

      if (newFilterStr.length === 0) {
        setfilteredProducts(products);
      } else {
        setfilteredProducts(
          products.filter((item) =>
            item.dong.toLowerCase().includes(newFilterStr)
          )
        );
      }
    }
  };

  return (
    <Dialog
      header={"Chọn dòng sản phẩm - " + category}
      visible={visible}
      style={{ width: "60vw", height: "75vh" }}
      onClose={onCancel}
      maximizable={true}
      buttons={[
        {
          label: "Chọn",
          severity: "primary",
          icon: " pi-check",
          onClick: onMyConfirm,
        },
        {
          label: "Hủy",
          severity: "secondary",
          onClick: onCancel,
        },
      ]}
    >
      <Toast ref={toast} position="top-right" />

      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {/* <SearchBar data={products} onSearch={onSearch} /> */}
        <InputText
          value={filterStr}
          style={{ width: "100%" }}
          placeholder="Tìm kiếm theo Dòng"
          onChange={onFilter}
        />

        <table>
          <thead>
            <tr>
              <th>Dòng</th>
              <th style={{ textAlign: "right" }}>Giá Bình thường</th>
              {category === 'VGA' ?
                <>
                  <th style={{ textAlign: "right" }}>Giá Hư, còn xuất hình</th>
                  <th style={{ textAlign: "right" }}>Giá Không hoạt động</th>
                </>
                : <th style={{ textAlign: "right" }}>Giá Hư hỏng</th>
              }
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr
                key={index + "_pl"}
                onClick={() => setSelectedProduct(product)}
                onDoubleClick={() => onSelectProduct(product)}
                style={{ cursor: "pointer" }}
                className={
                  selectedProduct?.id === product.id ? "selected-row" : ""
                }
              >
                <td>{product.dong}</td>

                <td style={{ textAlign: "right" }}>
                  {showPrice(product, "Bình thường")}
                </td>

                {category === 'VGA' ?
                  <>
                    <td style={{ textAlign: "right" }}>
                      {showPrice(product, "Hư, còn xuất hình")}
                    </td>

                    <td style={{ textAlign: "right" }}>
                      {showPrice(product, "Không hoạt động")}
                    </td>
                  </>
                  :
                  <td style={{ textAlign: "right" }}>
                    {showPrice(product, "Hư hỏng")}
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Dialog>
  );
};

export default ProductListDialog;
