import Backdrop from "./Backdrop";
import "./Loader.css";

const Loader = () => {
  return (
    <Backdrop open={true}>
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </Backdrop>
  );
};

export default Loader;
