import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import PrintForm from './PrintForm';

import "./ConfirmPurchaseDialog.css";

const storeOptions = [
  { value: 'CCH', label: 'Chọn cửa hàng' },
  { value: 'KVC', label: 'Kha Vạn Cân' },
  { value: 'TH', label: 'Thái Hà' },
  { value: 'HHT', label: 'Hoàng Hoa Thám' },
  { value: 'THD', label: 'Trần Hưng Đạo' }
]

const initialFormState = {
  store_id: storeOptions[0].value,
  benBan: '',
  soCCCD: '',
  noiCap: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội, Bộ Công an',
  ngayCap: null,
  diaChi: '',
  soDienThoai: '',
  imei: ''
};

const ConfirmPurchaseDialog = ({ visible, onHide, productName, condition, totalPrice, productData }) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [showPrintForm, setShowPrintForm] = useState(false);

  const resetForm = () => {
    setFormData(initialFormState);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    // Clear the error for this field
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined
    }));
  };

  const handleDateChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      ngayCap: e.value
    }));// Clear the error for this field
    setErrors(prevErrors => ({
      ...prevErrors,
      ngayCap: undefined
    }));
  };

  const validateForm = () => {
    const today = new Date()
    const newErrors = {};
    if (formData.store_id === 'CCH') newErrors.cuaHang = 'Chưa chọn cửa hàng !';
    if (!formData.benBan.trim()) newErrors.benBan = 'Bên bán là bắt buộc !';
    if (!formData.soCCCD.trim()) {
      newErrors.soCCCD = 'Số CCCD là bắt buộc !';
    }
    else {
      // Kiem tra CCCD co dung 12 chu so
      if (!(/^\d{9,}$/.test(formData.soCCCD.trim().replace(/\s+/g, '')))) newErrors.soCCCD = 'CCCD không hợp lệ !';
    }
    if (formData.ngayCap > today) newErrors.ngayCap = 'Ngày cấp không hợp lệ !';
    if (!formData.diaChi.trim()) newErrors.diaChi = 'Địa chỉ là bắt buộc !';
    if (!formData.soDienThoai.trim()) {
      newErrors.soDienThoai = 'SĐT là bắt buộc !';
    }
    else {
      // Kiem tra SĐT co >= 8 chu so
      if (!(/^\d{8,}$/.test(formData.soDienThoai.trim().replace(/\s+/g, '')))) newErrors.soDienThoai = 'SĐT không hợp lệ !';
    }
    if (!formData.imei.trim()) newErrors.imei = 'IMEI là bắt buộc !';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePrint = () => {
    if (validateForm()) {
      setShowPrintForm(true);
    } else {
      console.log('Form is invalid');
    }
  };

  const handleDialogHide = () => {
    resetForm();
    onHide();
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="In" icon="pi pi-print" onClick={handlePrint} autoFocus />
        <Button label="Hủy" onClick={onHide} className="p-button-secondary"/>
      </div>
    );
  };

  // useEffect(() => {
    // if (!visible) {
    //   resetForm();
    // }
  // }, [visible]);

  return (
    <>
      <Dialog
        header="Xác nhận mua"
        visible={visible}
        style={{ width: '68vw' }}
        footer={renderFooter()}
        onHide={handleDialogHide}
        maximizable={true}
      >
        <div className="p-fluid" style={{ paddingBlock: '0px' }}>
          <div className="p-field">
            <label htmlFor="cuaHang">Cửa hàng</label>
            <Dropdown
              id="cuaHang"
              name="cuaHang"
              placeholder="Chọn cửa hàng"
              value={formData?.store_id}
              onChange={(e) => {
                setFormData(prevState => ({
                  ...prevState,
                  store_id: storeOptions.find(item => item.value === e.target.value).value
                }));
              }}
              options={storeOptions.sort((a, b) => a.label.localeCompare(b.label))}
              className={classNames({ 'p-invalid': errors.cuaHang })}
            />
            {errors.cuaHang && <small className="p-error">{errors.cuaHang}</small>}
          </div>
          {/* <div style={{display: 'flex', justifyContent: 'space-between', gap: '30px'}}> */}
          <div className="p-field">
            <label htmlFor="benBan">Bên bán</label>
            <InputText
              id="benBan"
              name="benBan"
              placeholder='Nhập tên Bên Bán'
              value={formData.benBan}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.benBan })}
            />
            {errors.benBan && <small className="p-error">{errors.benBan}</small>}
          </div>
          {/* </div> */}
          <div className="p-field" style={{ width: '150px' }}>
            <label htmlFor="soDienThoai">Số điện thoại</label>
            <InputText
              id="soDienThoai"
              name="soDienThoai"
              placeholder='Nhập SĐT'
              value={formData.soDienThoai}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.soDienThoai })}
            />
            {errors.soDienThoai && <small className="p-error">{errors.soDienThoai}</small>}
          </div>
          <div className="p-field" style={{ flexGrow: '2' }}>
            <label htmlFor="diaChi">Địa chỉ</label>
            <InputTextarea
              id="diaChi"
              name="diaChi"
              rows={3}
              placeholder='Nhập địa chỉ'
              value={formData.diaChi}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.diaChi })}
            />
            {errors.diaChi && <small className="p-error">{errors.diaChi}</small>}
          </div>
          <div style={{ display: 'flex', gap: '30px' }}>
            <div className="p-field" style={{ width: '150px' }}>
              <label htmlFor="soCCCD">Số CCCD</label>
              <InputText
                id="soCCCD"
                name="soCCCD"
                placeholder='Nhập số CCCD'
                value={formData.soCCCD}
                onChange={handleInputChange}
                className={classNames({ 'p-invalid': errors.soCCCD })}
              />
              {errors.soCCCD && <small className="p-error">{errors.soCCCD}</small>}
            </div>
            <div className="p-field" style={{ width: '200px' }}>
              <label htmlFor="ngayCap">Ngày cấp</label>
              <Calendar
                id="ngayCap"
                name="ngayCap"
                placeholder='Nhập ngày cấp'
                value={formData.ngayCap}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
                showIcon
                className={classNames({ 'p-invalid': errors.ngayCap })}
              />
              {errors.ngayCap && <small className="p-error">{errors.ngayCap}</small>}
            </div>
            <div className="p-field" style={{ flexGrow: '2' }}>
              <label htmlFor="noiCap">Nơi cấp</label>
              <InputText
                id="noiCap"
                name="noiCap"
                placeholder='Nhập nơi cấp'
                value={formData.noiCap}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="p-field">
            <label htmlFor="imei">IMEI</label>
            <InputText
              id="imei"
              name="imei"
              placeholder='Nhập IMEI sản phẩm'
              value={formData.imei}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.imei })}
            />
            {errors.imei && <small className="p-error">{errors.imei}</small>}
          </div>
        </div>
      </Dialog>
      {showPrintForm &&
        <PrintForm
          // visible={showPrintForm}
          onHide={() => setShowPrintForm(false)}
          formData={{
            ...formData,
            // benBan: formData.benBan,
            // soDienThoai: formData.soDienThoai,
            // diaChi: formData.diaChi,
            // soCCCD: formData.soCCCD,
            // ngayCap: formData.ngayCap,
            // noiCap: formData.noiCap,
            // imei: formData.imei,
            productName: productName,
            unit: 'Cái',
            quantity: '1',
            unitPrice: totalPrice.toString(),
            totalPrice: totalPrice.toString(),
            condition: condition,
          }}
          productData={productData}
        />
      }
    </>
  );
};

export default ConfirmPurchaseDialog;