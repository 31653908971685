// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PrimeReact Dialog styles */
.p-dialog .p-dialog-content {
  padding: 2rem;
}

.p-dialog .p-dialog-header {
  padding: 1.5rem;
}

.p-dialog .p-dialog-footer {
  padding: 1.5rem;
}

/* Form styles within the dialog */
.p-fluid .p-field {
  margin-bottom: 1.5rem;
}

.p-fluid .p-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.p-fluid .p-field .p-inputtext,
.p-fluid .p-field .p-calendar {
  width: 100%;
}

.p-fluid .p-field .p-calendar .p-inputtext {
  width: 100%;
}

.p-fluid .p-field small.p-error {
  color: var(--error-color, #f44336);
  display: block;
  margin-top: 0.25rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .p-dialog {
    width: 90vw !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmPurchaseDialog.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA,kCAAkC;AAClC;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,cAAc;EACd,mBAAmB;AACrB;;AAEA,2BAA2B;AAC3B;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* PrimeReact Dialog styles */\n.p-dialog .p-dialog-content {\n  padding: 2rem;\n}\n\n.p-dialog .p-dialog-header {\n  padding: 1.5rem;\n}\n\n.p-dialog .p-dialog-footer {\n  padding: 1.5rem;\n}\n\n/* Form styles within the dialog */\n.p-fluid .p-field {\n  margin-bottom: 1.5rem;\n}\n\n.p-fluid .p-field label {\n  display: block;\n  margin-bottom: 0.5rem;\n  font-weight: 600;\n}\n\n.p-fluid .p-field .p-inputtext,\n.p-fluid .p-field .p-calendar {\n  width: 100%;\n}\n\n.p-fluid .p-field .p-calendar .p-inputtext {\n  width: 100%;\n}\n\n.p-fluid .p-field small.p-error {\n  color: var(--error-color, #f44336);\n  display: block;\n  margin-top: 0.25rem;\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n  .p-dialog {\n    width: 90vw !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
