import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

const MyField = ({ label, value, col = 12, row = 1 }) => {
  return (
    <div className={`field-v2 col-${col}`}>
      <label>{label}</label>
      {row === 1 && (
        <InputText
          id={label}
          disabled={true}
          value={value}
          style={{ width: "100%" }}
        />
      )}
      {row > 1 && (
        <InputTextarea
          id={label}
          disabled={true}
          rows={row}
          value={value}
          style={{ width: "100%" }}
        />
      )}
    </div>
  );
};

export default MyField;
