import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import ProductPriceTable from '../components/ProductPriceTable';
import { Loader, Header } from "../components";
import { Button } from 'primereact/button';
import './PriceInsert.css';

import { PERMISSIONS } from '../config';
import AuthServices from "../services/auth.services";
import ProductPriceService from "../services/productPrice.services";
import localStorageServices from "../services/localStorage.services";
import { InputText } from 'primereact/inputtext';

const PriceInsert = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [productData, setProductData] = useState(null);

  const [productConfig, setProductConfig] = useState({});

  const [productPrice, setProductPrice] = useState({
    dong: null,
    gia_base: null,
    gia_hang_hu_hong: null,
  });

  const toast = useRef(null);

  const showErr = (msg) => {
    if (toast.current) {
      toast.current.show({
        severity: 'error',
        detail: msg,
      });
    }
  };

  const loadProductData = async () => {
    setIsLoading(true);
    try {
      const product_data = await ProductPriceService.getProductPriceList();
      setProductData(product_data);
    } catch (error) {
      showErr('Không tải được danh sách sản phẩm. Vui lòng kiểm tra kết nối mạng hoặc thử lại sau.')
    } finally {
      setIsLoading(false);
    }
  };

  const loadProductConfig = async () => {
    setIsLoading(true);
    try {
      const product_config = await ProductPriceService.getProductPriceConfig();
      setProductConfig(product_config);
    } catch (error) {
      showErr('Không tải được cấu hình sản phẩm. Vui lòng kiểm tra kết nối mạng hoặc thử lại sau.')
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadProductData();
    loadProductConfig()
  }, []);

  const submitProductPrice = async () => {
    let productPriceObj = {
      dong: (productPrice.dong || "").trim(),
      gia_base: Number(productPrice.gia_base),
      gia_hang_hu_hong: Number(productPrice.gia_hang_hu_hong),
      created_by: localStorageServices.getCurrenUser().uuid,
    };

    let errors = [];

    if (productPriceObj.dong === "") {
      errors.push("Dòng chưa có giá trị");
    }

    if (
      !productPriceObj.gia_base ||
      productPriceObj.gia_base < 1 ||
      productPriceObj.gia_base > 1000000
    ) {
      errors.push("Giá Base không hợp lệ");
    }

    if (
      !productPriceObj.gia_hang_hu_hong ||
      productPriceObj.gia_hang_hu_hong < 1 ||
      productPriceObj.gia_hang_hu_hong > 1000000
    ) {
      errors.push("Giá Hàng hư hỏng không hợp lệ");
    }

    if (errors.length > 0) {
      toast.current.show({
        severity: "error",
        content: (
          <div>
            {errors.map((error, index) => (
              <React.Fragment key={index}>
                {error}
                <br />
              </React.Fragment>
            ))}
          </div>
        ),
      });
      return;
    }

    setIsLoading(true);
    ProductPriceService.insertProductPrice(productPriceObj)
      .then((response) => {
        if (response.code === 200) {
          toast.current.show({
            severity: "success",
            summary: "Cập nhật giá sản phẩm thành công !",
          });
          loadProductData();
          setProductPrice({});
        } else {
          showErr(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        showErr('Cập nhật giá sản phẩm không thành công !');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitProdutConfig = async () => {
    const updatedBy = localStorageServices.getCurrenUser().uuid;
    const updatedConfigObject = { updated_by: updatedBy };
    let errors = []

    for (const key in productConfig) {
      const value = Number(productConfig[key]);

      if (!value ||
        value < 1 ||
        value > 1000000
      ) {
        errors.push(`Giá trị ${key} không hợp lệ !`);
      }

      updatedConfigObject[key] = value;
    }

    if (errors.length > 0) {
      toast.current.show({
        severity: "error",
        content: (
          <div>
            {errors.map((error, index) => (
              <React.Fragment key={index}>
                {error}
                <br />
              </React.Fragment>
            ))}
          </div>
        ),
      });
      return;
    }

    setIsLoading(true);
    ProductPriceService.updateProductPriceConfig(updatedConfigObject)
      .then((response) => {
        if (response.code === 200) {
          toast.current.show({
            severity: "success",
            summary: "Cập nhật giá cấu hình sản phẩm thành công !",
          });
          loadProductConfig()
        } else {
          showErr(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        showErr("Cập nhật giá cấu hình sản phẩm không thành công !");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!productData && !productConfig) {
    return <div>Loading...</div>;
  }

  if (!AuthServices.hasPermission(PERMISSIONS.updateProduct)) {
    return (
      <div>
        <h2 style={{ paddingLeft: "30px", color: "#FF0000" }}>
          Bạn ko có quyền thao tác!
        </h2>
      </div>
    );
  }

  return (
    <div className="price-insert">
      {isLoading && <Loader />}

      <Toast ref={toast} className="custom-toast" />

      <ConfirmDialog />

      <Header header="Nhập giá sản phẩm" />

      <div className="price-insert-content">

        {productData && <ProductPriceTable productData={productData} />}

        <div className="price-input-section">
          <div className="form-group-insert">
            <h3>Bảng giá</h3>
            <table className="brand-table">
              <thead>
                <tr>
                  <th>Dòng</th>
                  <th>Giá base (Ngàn đồng)</th>
                  <th>Giá hàng hư hỏng (Ngàn đồng)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <InputText
                      type={"text"}
                      value={productPrice.dong}
                      onChange={(e) => setProductPrice({ ...productPrice, dong: e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type={"number"}
                      value={productPrice.gia_base}
                      onChange={(e) => setProductPrice({ ...productPrice, gia_base: e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type={"number"}
                      value={productPrice.gia_hang_hu_hong}
                      onChange={(e) => setProductPrice({ ...productPrice, gia_hang_hu_hong: e.target.value, })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button onClick={submitProductPrice}>
                Cập nhật giá sản phẩm
              </Button>
            </div>
          </div>
          <div className="form-group-insert">
            <h3>Brand</h3>
            <table className="brand-table">
              <thead>
                <tr>
                  <th>Brand tier 1</th>
                  <th>Brand tier 2</th>
                  <th>Brand tier 3</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Brand tier 1'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Brand tier 1': e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Brand tier 2'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Brand tier 2': e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Brand tier 3'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Brand tier 3': e.target.value, })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-group-insert">
            <h3>Bảo hành</h3>
            <table className="warranty-table">
              <thead>
                <tr>
                  <th>{">= 12 tháng"}</th>
                  <th>{"< 12 tháng"}</th>
                  <th>Hết</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Bảo hành >= 12 tháng'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Bảo hành >= 12 tháng': e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Bảo hành < 12 tháng'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Bảo hành < 12 tháng': e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Bảo hành hết'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Bảo hành hết': e.target.value, })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-group-insert">
            <h3>Ngoại hình</h3>
            <table className="ngoai-hinh-table">
              <thead>
                <tr>
                  <th>Mới, đẹp</th>
                  <th>Trung bình</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Ngoại hình mới, đẹp'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Ngoại hình mới, đẹp': e.target.value, })}
                    />
                  </td>
                  <td>
                    <InputText
                      type="number"
                      step="0.01"
                      value={productConfig['Ngoại hình trung bình'] || null}
                      onChange={(e) => setProductConfig({ ...productConfig, 'Ngoại hình trung bình': e.target.value, })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="form-group-insert box-group">
            <h3>
              Có box RTX 3060 trở lên{" "}
              <span style={{ fontWeight: "normal", fontStyle: "italic" }}>
                (đơn vị: ngàn đồng)
              </span>
            </h3>
            <InputText
              type="number"
              value={productConfig['Có box RTX 3060 trở lên'] || null}
              onChange={(e) => setProductConfig({ ...productConfig, 'Có box RTX 3060 trở lên': e.target.value, })}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button onClick={submitProdutConfig}>
              Cập nhật cấu hình sản phẩm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceInsert;