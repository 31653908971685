class LocalStorageServices {
  get(key) {
    try {
      let data = localStorage.getItem(key) || "";

      if (!key) {
        return null;
      }

      if (data) {
        return JSON.parse(data);
      }

      if (key === "permission" && process.env.REACT_APP_DEV_PERMISSIONS) {
        return JSON.parse(process.env.REACT_APP_DEV_PERMISSIONS);
      }

      return null;
    } catch (e) {
      console.error("Error get from localStorage", e);
      return null;
    }
  }

  getCurrenUser() {
    let currentUserLocal = this.get("user");
    return {
      email: currentUserLocal?.profile?.email || "N/A",
      name: currentUserLocal?.profile?.name || "N/A",
      uuid: currentUserLocal?.uuid || process.env.REACT_APP_DEV_UUID,
      isMaster: currentUserLocal?.profile?.is_master === 1,
    };
  }

  getToken() {
    if (process.env.REACT_APP_MODE === "development") {
      return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJQcm9maWxlSWQiOjEsIkxvZ2luQnlVc2VyTmFtZSI6ImFkbWluQGdlYXJ2bi5jb20iLCJMb2dpbkF0IjoiMjAyNC0xMC0yMVQxNTozMDoyMS40NjMyOTkwODErMDc6MDAifQ.GAAUeyz1TxQBN9a5N6QsIEbsdfjblzBcLsYtrPGmjx4";
    }

    let tokenInfo = this.get("token") || {};
    return tokenInfo.token || process.env.REACT_APP_DEV_TOKEN;
  }
}

const MyServices = new LocalStorageServices();
export default MyServices;
