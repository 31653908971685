import React from 'react';
import { RadioButton as PrimeRadioButton } from 'primereact/radiobutton';

const RadioButton = ({ label, value, checked, onChange }) => {
  return (
    <div className="flex align-items-center">
      <PrimeRadioButton
        inputId={value}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e)}
      />
      <label htmlFor={value} style={{marginLeft: '10px'}}>{label}</label>
    </div>
  );
};

export default RadioButton;