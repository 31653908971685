import { useAppContext } from "../AppContext";

const Header = ({ header }) => {
  const appContext = useAppContext();

  return (
    <div className="app-header">
      <h2>{header}</h2>
      <div className="app-header-info">
        <p>{`Last Updated: ${appContext.lastUpdated.toLocaleString()}`}</p>
      </div>
    </div>
  );
};
export default Header;
