import { InputText } from "primereact/inputtext";

const MyField = ({ label, value, onChange, col = 4, error }) => {
  return (
    <div className={`field col-${col}`}>
      <label htmlFor={label}>{label}</label>

      <InputText
        id={label}
        value={value}
        style={{ width: "100%" }}
        onChange={onChange}
      />

      {error && <small className="p-error">{error}</small>}
    </div>
  );
};

export default MyField;
