import React from "react";

import { Button } from "primereact/button";
import { Dialog as PrimeDialog } from "primereact/dialog";

const Dialog = ({
  visible = true,
  maximizable = true,
  header,
  style = { width: "86vw", height: "95vh" },
  buttons,
  onHide,
  onClose,
  children,
}) => {
  const renderFooter = () => {
    if (!buttons) {
      return <></>;
    }
    return (
      <div
        style={{
          display: "felx",
          gap: "20px",
        }}
      >
        {buttons.map((btn, index) => (
          <Button
            key={index + "_" + btn.label}
            label={btn.label}
            severity={btn.severity || "success"}
            icon={"pi " + btn.icon || ""}
            onClick={btn.onClick}
            outlined={btn.outlined}
            autoFocus={index === 0}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <PrimeDialog
        header={header}
        visible={visible}
        style={style}
        footer={renderFooter()}
        maximizable={maximizable}
        onHide={onHide || onClose}
        className="custom-dialog"
      >
        {children}
      </PrimeDialog>
    </>
  );
};

export default Dialog;
