import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const SelectInput = ({ label, value, onChange, options }) => {
  return (
    <div className="select-input">
      <h3>{label}</h3>
      <Dropdown
        value={value}
        options={options}
        onChange={(e) => onChange(e)}
        placeholder="Select an option"
        style={{width: '300px'}}
      />
    </div>
  );
};

export default SelectInput;