import { InputTextarea } from "primereact/inputtextarea";

const MyField = ({ label, value, onChange, col = 4, row = 3, error }) => {
  return (
    <div className={`field col-${col}`}>
      <label htmlFor={label}>{label}</label>

      <InputTextarea
        id={label}
        rows={row}
        value={value}
        style={{ width: "100%" }}
        onChange={onChange}
      />
      {error && <small className="p-error">{error}</small>}
    </div>
  );
};

export default MyField;
