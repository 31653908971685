import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";

import { PUBLIC_URL, PERMISSIONS } from "../config";
import AuthServices from "../services/auth.services";

const NavItem = ({ label, path, location }) => {
  const fullPath = `${PUBLIC_URL}/${path}`;
  const isSelected = location.pathname === fullPath;

  return (
    <li>
      <Link to={fullPath} className={isSelected ? "active" : ""}>
        {label}
      </Link>
    </li>
  );
};

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Định giá hàng cũ</h2>
      </div>

      <nav className="sidebar-nav">
        <ul>
          <NavItem
            label="Định giá sản phẩm"
            path="product-pricing"
            location={location}
          />

          {AuthServices.hasPermission(PERMISSIONS.updateProduct) && (
            <NavItem
              label="Nhập giá sản phẩm"
              path="price-insert"
              location={location}
            />
          )}

          <NavItem
            label="Danh sách phiếu"
            path="doc-list"
            location={location}
          />
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
