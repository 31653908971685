import { InputTextarea } from "primereact/inputtextarea";

const MyField = ({ label, value, onChange, col = 12, row = 3, error }) => {
  return (
    <div className={`field-v2 col-${col}`}>
      <label htmlFor={label}>{label}</label>
      <div style={{ width: "100%" }}>
        <InputTextarea
          id={label}
          rows={row}
          value={value}
          style={{ width: "100%" }}
          onChange={onChange}
        />
        {error && <small className="p-error">{error}</small>}
      </div>
    </div>
  );
};

export default MyField;
