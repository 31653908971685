import React from "react";
const ApContext = React.createContext();

function AppProvider({ children }) {
  const lastUpdated = new Date(); // startup time

  return (
    <ApContext.Provider
      value={{
        lastUpdated,
      }}
    >
      {children}
    </ApContext.Provider>
  );
}

function useAppContext() {
  const context = React.useContext(ApContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
}

export { AppProvider, useAppContext };
