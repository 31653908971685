import React from 'react';
import * as DateFns from "date-fns";

const RowInfo = ({ label, value, labelStyle, valueStyle }) => {
  return <tr>
    <td style={labelStyle}>{label}</td>
    <td style={valueStyle}>{value}</td>
  </tr>
}

const DocInfo = ({ doc }) => {

  return (
    <div>
      <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '16px' }}>
        <div>Danh mục : {doc.category}</div>
        <div>Dòng : {doc.product_name}</div>
        <div>SKU : {doc.sku}</div>
        <div>Nhân viên : {doc.created_by}</div>
        <div>Thời gian : {DateFns.format(doc.created_at, "HH:mm:ss dd/MM/yyyy")}</div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '20px' }}>
        <div className="bill doc_info">
          <h3>Thông tin giá</h3>
          <table>
            <thead>
              <tr>
                <th>Mục</th>
                <th>Giá (VNĐ)</th>
              </tr>
            </thead>
            <tbody>
              <RowInfo label={`Giá ${doc.product_status}`} value={`${doc.price_status.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={`Thương hiệu ${doc.product_brand_tier || doc.product_brand}`} value={`${doc.price_tier.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={`Bảo hành ${doc.product_status}`} value={`${doc.price_warranty.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={`Ngoại hình ${doc.product_appearance}`} value={`${doc.price_appearance.toLocaleString('en-US')} VNĐ`} />
              <RowInfo label={'Tổng'} value={`${doc.total_amount.toLocaleString('en-US')} VNĐ`} labelStyle={{ fontWeight: 'bold' }} valueStyle={{ fontWeight: 'bold' }} />
            </tbody>
          </table>
        </div>
        <div className="bill doc_info seller">
          <h3>Thông tin Bên bán</h3>
          <table>
            <tbody>
              <RowInfo label={'Tên bên bán'} value={doc.seller_name} />
              <RowInfo label={'Số điện thoại'} value={doc.seller_phone} />
              <RowInfo label={'Địa chỉ'} value={doc.seller_address} />
              <RowInfo label={'Số CCCD'} value={doc.seller_identify_no} />
              <RowInfo label={'Ngày cấp'} value={DateFns.format(doc.seller_identify_no_date, "dd/MM/yyyy")} />
              <RowInfo label={'Nơi cấp'} value={doc.seller_identify_no_provider} />
              <RowInfo label={'IMEI'} value={doc.imei} />
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
};

export default DocInfo;
